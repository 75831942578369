<template>
    <modal-lateral ref="modalFiltro" titulo="Filtro">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 106px)">
            <div class="row mx-3">
                <div class="col-12">
                    <p class="text-general f-12 pl-3">
                        Rango de fecha
                    </p>
                    <el-select v-model="value" class="w-100" size="small">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                </div>
                <div class="col-12">
                    <p class="text-general f-12 pl-3 mt-3">
                        Rango de fecha
                    </p>
                    <el-date-picker
                    v-model="value1"
                    placeholder="Seleccionar"
                    size="small"
                    class="w-100"
                    type="date"
                    />
                </div>
            </div>
            <div class="row mx-3">
                <div class="col-12">
                    <p class="text-general f-12 pl-3 mt-3">
                        Rango de precios
                    </p>
                    <div class="row mx-0">
                        <div class="col pl-0 pr-2">
                            <el-input v-model="menor" placeholder="Menor" class="w-100" size="small" />
                        </div>
                        <div class="col pr-0 pl-2">
                            <el-input v-model="menor" placeholder="Menor" class="w-100" size="small" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div class="col-12 mb-2 mx-0 bg-linea" />
            <div class="btn-outline f-14 px-3 mr-2">
                Limpiar
            </div>
            <div class="btn-general f-14 px-3 ml-2">
                Filtrar
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            value1: '',
            menor: ''
        }
    },
    methods: {
        toggle(){
            this.$refs.modalFiltro.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.bg-linea{
    background: linear-gradient(to right, #fff, #D1D1D1, #fff);
    height: 1px;
}
</style>